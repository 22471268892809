import React, { useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import AddEditAddress from './AddEditAddress'
import { useDispatch } from 'react-redux'
import type { AppDispatch } from '../../container/redux/store'
import { deleteUserAddress } from '../../container/redux/slices/AccountsSlice'
interface IAddressTile {
  address: Address
}
interface Address {
  addressId: string
  address: string
  pincode: string
  state: string
  city: string
  country: string
  __typename: string
}

const AddressTile = ({ address }: IAddressTile): JSX.Element => {
  const [showAction, setShowAction] = useState(false)
  const [showEditAddressTile, setShowEditAddressTile] = useState(false)
  const [customerId] = useState(localStorage.getItem('customerId') ?? '')
  const dispatch = useDispatch<AppDispatch>()
  const handleBiDotsMouseEnter = (): void => {
    setShowAction(true)
  }
  const handleActionMenuMouseLeave = (): void => {
    setShowAction(false)
  }
  const handleEditAddress = (): void => {
    setShowAction(false)
    setShowEditAddressTile(true)
  }

  const handleDeleteAddress = (): void => {
    void dispatch(deleteUserAddress({
      addressId: address.addressId,
      customerId
    }))
  }

  return (
    <div className='my-3'>
      {!showEditAddressTile && <div className='border-[1px] border-[#c5b4b4] p-2'>
        <div className='py-1'>
          <div className='bg-[#c5b4b4] px-2 py-1 w-fit text-xs inline'>
            {'Home'}
          </div>
          {!showAction && <BiDotsVerticalRounded className='inline float-right cursor-pointer' onMouseEnter={handleBiDotsMouseEnter} />}
          {showAction && <div className='float-right bg-navbar cursor-pointer' onMouseLeave={handleActionMenuMouseLeave}>
            <div className='px-2 py-1' onClick={() => { handleEditAddress() }} >Edit</div>
            <div className='px-2 py-1' onClick={() => { handleDeleteAddress() }}>Delete</div>
          </div>}
        </div>
        <div className='flex'>
          <div className='pr-5'>{'Abhishek'}</div>
          <div>{'123456'}</div>
        </div>

        <div>
          <span className='pr-5'>
            {address.address}
          </span>
          <span className='whitespace-nowrap'>
            PIN : {address.pincode}
          </span>
        </div>
      </div>}
      {showEditAddressTile && <AddEditAddress onCancel={() => { setShowEditAddressTile(false) }} isEdit={true} address={address} />}
    </div>
  )
}

export default AddressTile
