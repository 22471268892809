import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MdCurrencyRupee } from 'react-icons/md'
import { SERVER_URI_PATH } from '../../Constants'

interface IItemListRecord {
  itemList?: Array<Record<string, any>>
  shouldSliceForSmallDevices: boolean
}

// shouldSliceForSmallDevices is used in home page to show latest item preview
const ItemsListGrid = ({ itemList, shouldSliceForSmallDevices }: IItemListRecord): JSX.Element => {
  let itemListsm
  if (shouldSliceForSmallDevices) {
    itemListsm = itemList?.slice(0, 6)
  }
  return (
    <div>
      {shouldSliceForSmallDevices
        ? <div>
          <div className="flex lg:hidden w-full flex-wrap justify-start">
            {itemListsm?.map((item, index) => {
              return <ItemCard itemId={item.id} itemType={item.__typename} title={item.name} price={item.pricePerPiece} primaryImageUrl={SERVER_URI_PATH + String(item.primaryImage.imageUrl)} key={index} />
            })}
          </div>
          <div className="hidden lg:flex w-full flex-wrap justify-start">
            {itemList?.map((item, index) => {
              return <ItemCard itemId={item.id} itemType={item.__typename} title={item.name} price={item.pricePerPiece} primaryImageUrl={SERVER_URI_PATH + String(item.primaryImage.imageUrl)} key={index} />
            })}
          </div></div>
        : null}
      {!shouldSliceForSmallDevices
        ? <div className="flex w-full flex-wrap justify-start">
          {itemList?.map((item, index) => {
            return <ItemCard itemId={item.id} itemType={item.__typename} title={item.name} price={item.pricePerPiece} primaryImageUrl={SERVER_URI_PATH + String(item.primaryImage.imageUrl)} key={index} />
          })}
        </div>
        : null
      }
    </div>
  )
}

interface IItemRecord {
  title: string
  price: string
  primaryImageUrl: string
  itemType: string
  itemId: string
}

const ItemCard = ({ title, price, primaryImageUrl, itemType, itemId }: IItemRecord): JSX.Element => {
  const navigate = useNavigate()
  const routeToProductDetails = (): void => {
    const itemPath = '/product-details?type=' + itemType + '&id=' + itemId
    navigate(itemPath)
  }

  return (
    <div className="w-1/2 sm:w-1/3 lg:w-1/4 p-3 border mb-20 rounded-md flex flex-col justify-between">
      <div>
        <img
          onClick={routeToProductDetails}
          className='cursor-pointer h-56 md:h-60 lg:h-96 xl:h-96 2xl:h-100 3xl:h-104 w-full bg-cover object-cover'
          src={primaryImageUrl}
        />
        <p className="text-black m-1 cursor-pointer sm:text-base text-xxs">{title}</p>
      </div>
      <div className="flex flex-col items-end">
        <p className="text-sm mt-1 flex items-center md:text-xl text-text-primary font-lato font-light cursor-pointer">
          <MdCurrencyRupee className='inline' /> {price}/-</p>
        <p className="text-black text-xs  cursor-pointer">(Inclusive GST)</p>
      </div>
    </div>
  )
}

export default ItemsListGrid
