import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { SERVER_URI } from '../Constants'

const link = createHttpLink({
  uri: SERVER_URI + '/graphql/',
  credentials: 'include'
})

const graphqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  link
})

export default graphqlClient
