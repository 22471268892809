import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {
  ProductDetailsContainer,
  KurtiListContainer,
  HomeContainer,
  CartContainer,
  AnarkaliListContainer,
  AliaListContainer,
  NairaCutListContainer,
  UnStitchedSuitListContainer,
  CoordListContainer,
  WishlistContainer,
  AccountsContainer,
  PlusSizesListContainer
} from './container'
import { store } from './container/redux/store'
import { AddressComponent, WishlistComponent } from './component'
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom'
import Header from './component/common/Header'
import Footer from './component/common/Footer'
import AppStyles from './component/common/styles/AppStyles'
import UserProfileComponent from './component/UserProfileComponent'
import { ApolloProvider } from '@apollo/client'
import ScrollToTop from './helper/ScrollToTop'
import RootErrorBoundary from './helper/RootErrorBoundary'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import graphqlClient from './helper/ApolloClient'

const App = (): JSX.Element => {
  return (
        <div className={AppStyles.container}>
            <ScrollToTop />
            <Header />
            <Outlet />
            <Footer />
            <ToastContainer />
        </div>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/',
        element: <HomeContainer />
      },
      {
        path: '/product-details',
        element: <ProductDetailsContainer />
      },
      {
        path: '/cart',
        element: <CartContainer />
      },
      {
        path: '/wishlist',
        element: <WishlistContainer />
      },
      {
        path: '/kurtis',
        element: <KurtiListContainer />
      },
      {
        path: '/alias',
        element: <AliaListContainer />
      },
      {
        path: '/anarkalis',
        element: <AnarkaliListContainer />
      },
      {
        path: '/nairaCuts',
        element: <NairaCutListContainer />
      },
      {
        path: '/plusSizes',
        element: <PlusSizesListContainer />
      },
      {
        path: '/unstitchedSuits',
        element: <UnStitchedSuitListContainer />
      },
      {
        path: '/coordSets',
        element: <CoordListContainer />
      },
      {
        path: '/accounts',
        element: <AccountsContainer />,
        children: [
          {
            path: '/accounts',
            element: <UserProfileComponent />
          },
          {
            path: '/accounts/profile',
            element: <UserProfileComponent />
          },
          {
            path: '/accounts/address',
            element: <AddressComponent />
          },
          {
            path: '/accounts/wishlist',
            element: <WishlistComponent />
          }
        ]
      }
    ],
    errorElement: <RootErrorBoundary />
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ApolloProvider client={graphqlClient}>
                <RouterProvider router={router} />
            </ApolloProvider>
        </Provider>
    </React.StrictMode>
)
