import React from 'react'
import { Logo, NavBar, ActionsBar } from '../../component'
import headerStyles from './styles/HeaderStyles'
import { Link } from 'react-router-dom'
const Header = (): JSX.Element => {
  return (
    <div className={headerStyles.header}>
      <div className={headerStyles.titleBar}>
        <div className={headerStyles.leading}>
          <Link to='/'><Logo /></Link>
          <div className={headerStyles.leadingTitleContainer}>
            <div className={headerStyles.title}>
              <h1 className={headerStyles.titleHeading}>
                Lowest prices. Best in quality
              </h1>
              <div className={headerStyles.titleSubHeading}>
                Jasavya Lifestyle Pvt. Ltd.
              </div>
            </div>
          </div>
        </div>
        <ActionsBar />
      </div>
      <NavBar />
    </div>
  )
}

export default Header
