import React, { useEffect, useState } from 'react'
import { HiMenuAlt3 } from 'react-icons/hi'
import navBarStyles from './styles/NavBarStyles'
import { useNavigate } from 'react-router-dom'
import { clsx } from 'clsx'

const NavBar = (): JSX.Element => {
  const navigate = useNavigate()
  const [isKurtiSelected, setIsKurtiSelected] = useState(location.pathname === '/kurtis')
  const [isAliaSelected, setIsAliaSelected] = useState(location.pathname === '/alia')
  const [isCoOrdSelected, setIsCoOrdSelected] = useState(location.pathname === '/coordSets')
  const [isAnarkaliSelected, setIsAnarkaliSelected] = useState(location.pathname === '/anarkalis')
  const [isNairaCutSelected, setIsNairaCutSelected] = useState(location.pathname === '/nairaCuts')
  const [isUnstitchedSuitSelected, setIsUnstitchedSuitSelected] = useState(location.pathname === '/unstitchedSuits')
  const [isPlusSizesSelected, setIsPlusSizesSelected] = useState(location.pathname === '/plusSizes')
  useEffect(() => {
    if (location.pathname === '/') {
      setIsKurtiSelected(false)
      setIsAliaSelected(false)
      setIsCoOrdSelected(false)
      setIsAnarkaliSelected(false)
      setIsNairaCutSelected(false)
      setIsUnstitchedSuitSelected(false)
      setIsPlusSizesSelected(false)
    }
  })
  const selectKurtis = (): void => {
    setIsKurtiSelected(true)
    setIsAliaSelected(false)
    setIsCoOrdSelected(false)
    setIsAnarkaliSelected(false)
    setIsNairaCutSelected(false)
    setIsUnstitchedSuitSelected(false)
    setIsPlusSizesSelected(false)
    navigate('/kurtis?page=1')
  }
  const selectAlia = (): void => {
    setIsKurtiSelected(false)
    setIsAliaSelected(true)
    setIsCoOrdSelected(false)
    setIsAnarkaliSelected(false)
    setIsNairaCutSelected(false)
    setIsUnstitchedSuitSelected(false)
    setIsPlusSizesSelected(false)
    navigate('/alias?page=1')
  }
  const selectCoord = (): void => {
    setIsKurtiSelected(false)
    setIsAliaSelected(false)
    setIsCoOrdSelected(true)
    setIsAnarkaliSelected(false)
    setIsNairaCutSelected(false)
    setIsUnstitchedSuitSelected(false)
    setIsPlusSizesSelected(false)
    navigate('/coordSets?page=1')
  }
  const selectAnarkali = (): void => {
    setIsKurtiSelected(false)
    setIsAliaSelected(false)
    setIsCoOrdSelected(false)
    setIsAnarkaliSelected(true)
    setIsNairaCutSelected(false)
    setIsUnstitchedSuitSelected(false)
    setIsPlusSizesSelected(false)
    navigate('/anarkalis?page=1')
  }
  const selectNairaCut = (): void => {
    setIsKurtiSelected(false)
    setIsAliaSelected(false)
    setIsCoOrdSelected(false)
    setIsAnarkaliSelected(false)
    setIsNairaCutSelected(true)
    setIsUnstitchedSuitSelected(false)
    setIsPlusSizesSelected(false)
    navigate('/nairaCuts?page=1')
  }
  const selectUnstitchedSuit = (): void => {
    setIsKurtiSelected(false)
    setIsAliaSelected(false)
    setIsCoOrdSelected(false)
    setIsAnarkaliSelected(false)
    setIsNairaCutSelected(false)
    setIsUnstitchedSuitSelected(true)
    setIsPlusSizesSelected(false)
    navigate('/unstitchedSuits?page=1')
  }
  const selectPlusSize = (): void => {
    setIsKurtiSelected(false)
    setIsAliaSelected(false)
    setIsCoOrdSelected(false)
    setIsAnarkaliSelected(false)
    setIsNairaCutSelected(false)
    setIsUnstitchedSuitSelected(false)
    setIsPlusSizesSelected(true)
    navigate('/plusSizes')
  }

  const NavButtons = (): JSX.Element => {
    return (
      <>
        <NavButton name={'KURTIS'} onClick={selectKurtis} isSelected={isKurtiSelected} />
        <NavButton name={'ALIA'} onClick={selectAlia} isSelected={isAliaSelected} />
        <NavButton name={'CO ORD SETS'} onClick={selectCoord} isSelected={isCoOrdSelected} />
        <NavButton name={'ANARKALIS'} onClick={selectAnarkali} isSelected={isAnarkaliSelected} />
        <NavButton name={'NAIRA CUTS'} onClick={selectNairaCut} isSelected={isNairaCutSelected} />
        <NavButton name={'UN-STITCHED SUITS'} onClick={selectUnstitchedSuit} isSelected={isUnstitchedSuitSelected} />
        <NavButton name={'+PLUS SIZES'} onClick={selectPlusSize} isSelected={isPlusSizesSelected} />
      </>
    )
  }

  const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState(false)
  return (
    <div>
      <div className={navBarStyles.mobileMenu}>
        <HiMenuAlt3
          onClick={() => {
            setIsMobileNavbarOpen(!isMobileNavbarOpen)
          }}
          color="#f3dc92"
          className={navBarStyles.mobileMenuIcon}
        />
        <div className={clsx(
          'md:hidden flex flex-col items-center bg-navbar w-full transition-all ease-linear',
          isMobileNavbarOpen ? 'visible opacity-100' : 'h-0 collapse opacity-0')}
        >
          <NavButtons />
        </div>
      </div>
      <nav className={navBarStyles.desktopMenuBar}>
        <ul className={navBarStyles.desktopMenuBarList}>
          <NavButtons />
        </ul>
      </nav>
    </div>
  )
}

const NavButton = ({ name, onClick, isSelected }: { name: string, onClick: () => void, isSelected: boolean }): JSX.Element => {
  return (
    <div className={isSelected ? navBarStyles.navBarButtonSelected : undefined}>
      <li onClick={onClick} className={navBarStyles.navBarButton}>{name}</li>
    </div>)
}

export default NavBar
