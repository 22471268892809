import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DELETE_ADDRESS_MUTATION, GET_USER_DETAILS_QUERY, INSERT_ADDRESS_MUTATION, UPDATE_ADDRESS_MUTATION } from '../../../graphql/userQueries'
import graphqlClient from '../../../helper/ApolloClient'
import { toast } from 'react-toastify'
interface ProfileData {
  __typename: string
  customerId: string
  profile: {
    __typename: string
    phone: string
    name: string
    email: string
    gender: string
    addresses: AddressList
  }
}

interface Address {
  addressId: string
  address: string
  pincode: string
  state: string
  city: string
  country: string
  __typename: string
}

type AddressList = Address[]

interface AccountsState {
  profileData: ProfileData
  addressData: AddressList
  ordersData: any[]
  wishListData: any[]
  isLoading: boolean
  error: string | null
}

const initialState: AccountsState = {
  profileData: {
    __typename: '',
    customerId: '',
    profile: {
      __typename: '',
      phone: '0',
      name: '',
      email: '',
      gender: '',
      addresses: []
    }
  },
  addressData: [],
  ordersData: [],
  wishListData: [],
  isLoading: false,
  error: null
}

export const fetchUserDetails = createAsyncThunk<ProfileData, string>(
  'account/fetchUserDetails',
  async (userId: string) => {
    const { data: userData } = await graphqlClient.query({
      query: GET_USER_DETAILS_QUERY,
      variables: { customerId: userId },
      fetchPolicy: 'no-cache'
    })
    return userData.customer
  }
)

export const updateUserAddress = createAsyncThunk(
  'account/updateUserAddress',
  async ({
    addressId,
    address,
    pincode,
    state,
    city,
    country,
    customerId
  }: {
    addressId: string
    address: string
    pincode: string
    state: string
    city: string
    country: string
    customerId: string
  }, { dispatch }) => {
    try {
      const { data: updatedAddressData } = await graphqlClient.mutate({
        mutation: UPDATE_ADDRESS_MUTATION,
        variables: {
          addressId,
          customerId,
          address,
          pincode,
          state,
          city,
          country
        }
      })
      toast.success('Address updated Successfully')
      await dispatch(fetchUserDetails(customerId))
      return updatedAddressData.updateAddress
    } catch (error: any) {
      throw new Error(error.message)
    }
  }
)

export const insertUserAddress = createAsyncThunk('account/insertUserAddress', async (
  {
    addressId,
    address,
    pincode,
    state,
    city,
    country,
    customerId
  }: {
    addressId: string
    address: string
    pincode: string
    state: string
    city: string
    country: string
    customerId: string
  }, { dispatch }
) => {
  const { data: insertAddressData } = await graphqlClient.mutate({
    mutation: INSERT_ADDRESS_MUTATION,
    variables: {
      customerId,
      address,
      pincode,
      state,
      city,
      country
    }
  })
  if (insertAddressData.createAddress.error === null) {
    toast.success('Address created successfully !')
    await dispatch(fetchUserDetails(customerId))
  } else {
    toast.error('Failed to create address')
  }
  return insertAddressData
})

export const deleteUserAddress = createAsyncThunk('account/deleteUserAddress', async (
  {
    addressId,
    customerId
  }: {
    addressId: string
    customerId: string
  }, { dispatch }
) => {
  const { data: deleteAddressData } = await graphqlClient.mutate({
    mutation: DELETE_ADDRESS_MUTATION,
    variables: {
      customerId,
      addressId
    }
  })
  if (deleteAddressData.deleteAddress.error === null) {
    toast.success('Address deleted successfully !')
    await dispatch(fetchUserDetails(customerId))
  } else {
    toast.error('Failed to delete address')
  }
  return deleteAddressData
})

const AccountsSlice = createSlice({
  name: 'AccountsSlice',
  initialState,
  reducers: {
    setProfileData: (state, actions) => {
      state.profileData = actions.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserDetails.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.profileData = action.payload
        state.addressData = action.payload.profile.addresses
        state.isLoading = false
        state.error = null
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? 'Failed to fetch user details'
      })
      .addCase(updateUserAddress.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateUserAddress.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
      })
      .addCase(updateUserAddress.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? 'Failed to update user address'
      })
      .addCase(insertUserAddress.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(insertUserAddress.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
      })
      .addCase(insertUserAddress.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? 'Failed to insert user address'
      })
      .addCase(deleteUserAddress.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(deleteUserAddress.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
      })
      .addCase(deleteUserAddress.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? 'Failed to delete user address'
      })
  }
})

export const { setProfileData } = AccountsSlice.actions
export default AccountsSlice.reducer
