import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import type { RootState } from '../container/redux/store'
import Collapsible from './common/Collapsible'
import UserProfileComponent from './UserProfileComponent'
import AddressComponent from './AddressComponent'
import WishlistComponent from './WishlistComponent'

const AccountsComponent = (): JSX.Element => {
  const userData = useSelector((state: RootState) => state.AccountsSlice.profileData)
  const location = useLocation()
  const settingsActiveColor = 'bg-[#310505]' // use secondary color ##

  return (
    <div>
      <div className='hidden sm:flex'>
        <div className='w-[30%] p-5' >
          <div className='flex-row items-start border-b-[1px] border-[#c5b4b4] pb-5'>
            <div>Namastey</div>
            <div className='text-2xl'>{userData?.profile?.name}</div>
          </div>
          <div className='p-3'>
            <div>
              <span className='text-lg py-3 block'>Settings:</span>
            </div>
            <Link to='/accounts/profile'>
              <div className={'p-2 ' + ((location.pathname === '/accounts/profile' || location.pathname === '/accounts') ? settingsActiveColor : '')}>Profile</div>
            </Link>
            <Link to='/accounts/address'>
              <div className={'p-2 ' + (location.pathname === '/accounts/address' ? settingsActiveColor : '')}>Address</div>
            </Link>
            <div className='p-2'>My Orders</div>
            <Link to='/accounts/wishlist'>
              <div className={'p-2 ' + (location.pathname === '/accounts/wishlist' ? settingsActiveColor : '')}>My Wishlist</div>
            </Link>
          </div>
        </div>
        <div className='w-[70%] p-5 mt-5 border-l-[1px] border-[#c5b4b4]'>
          <Outlet />
        </div>
      </div>
      <div className='block sm:hidden'>
        <div>
          <div className='flex-row items-start border-b-[1px] border-[#c5b4b4] pb-5 pl-5'>
            <div>Namastey</div>
            <div className='text-2xl'>{userData?.profile?.name}</div>
          </div>
          <div>
            <div className='text-2xl p-4'>Settings :</div>
            <div className='p-2'>
              <Link to='/accounts/profile'>
                <Collapsible isBodyHidden={true} header='Profile' body={<UserProfileComponent />} />
              </Link>
              <Link to='/accounts/address'>
                <Collapsible isBodyHidden={true} header='Address' body={<AddressComponent />} />
              </Link>
              <Collapsible isBodyHidden={true} header='My order' body={<div>comming soon</div>} />
              <Link to='/accounts/wishlist'>
                <Collapsible isBodyHidden={true} header='Wishlist' body={<WishlistComponent />} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountsComponent
