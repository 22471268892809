import { createSlice } from '@reduxjs/toolkit'
import { CATEGORY_SELECTION_MAP } from '../../../Constants'

const initialState = {
  selectedCategory: CATEGORY_SELECTION_MAP
}

const PlusSizeContainerSlice = createSlice({
  name: 'plusSizeContainerSlice',
  initialState,
  reducers: {
    setSelectedCategory: (state, actions) => {
      state.selectedCategory = actions.payload
    }
  }
})

export const { setSelectedCategory } = PlusSizeContainerSlice.actions
export default PlusSizeContainerSlice.reducer
