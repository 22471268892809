import React from 'react'

import { FaUserAlt } from 'react-icons/fa'
import { BsFillBagHeartFill, BsCart4 } from 'react-icons/bs'
import actionButtonStyles from './styles/ActionButtonStyles'
import { useNavigate } from 'react-router-dom'

const ActionsBar = (): JSX.Element => {
  const navigate = useNavigate()
  return (
    <div className={actionButtonStyles.actionButtonContainer}>
      <BsFillBagHeartFill
        title="wishlist"
        color="#f3dc92"
        onClick={() => {
          navigate('/wishlist')
        }}
        className={actionButtonStyles.actionButton}
      />
      <BsCart4
        title="Cart"
        color="#f3dc92"
        onClick={() => {
          navigate('/cart')
        }}
        className={actionButtonStyles.actionButton}
      />
      <FaUserAlt
        title="Account"
        color="#f3dc92"
        onClick={() => {
          navigate('/accounts')
        }}
        className={actionButtonStyles.actionButton}
      />
    </div>
  )
}

export default ActionsBar
