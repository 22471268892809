import React from 'react'
import catImageSrc from '../../assets/images/optimized/cuteCat.jpg'

const EmptyCart = (): JSX.Element => {
  const emptyCartQuotes = 'Your cart is empty , but here is a cute cat instead !'

  return (
    <div className='p-3 flex flex-col justify-center'>
      <img className='block w-[39%] h-auto m-auto' src={catImageSrc} alt="My Image" />
      <div className='py-3 italic m-auto'>{emptyCartQuotes}</div>
    </div>
  )
}

export default EmptyCart
