import React from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'

function RootErrorBoundary (): JSX.Element {
  const error: any = useRouteError()

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <div className="text-4xl text-gray-700">This page does not exist!</div>
        </div>
      )
    }

    if (error.status === 401) {
      return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <div className="text-4xl text-gray-700">You are not authorized to see this</div>
        </div>
      )
    }

    if (error.status === 503) {
      return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <div className="text-4xl text-gray-700">Looks like our API is down</div>
        </div>
      )
    }

    if (error.status === 418) {
      return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
          <div className="text-4xl text-gray-700">🫖</div>
        </div>
      )
    }
  }

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-4xl text-gray-700">Something went wrong</div>
    </div>
  )
}

export default RootErrorBoundary
