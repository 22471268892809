import React from 'react'

interface ISizeBadger {
  sizeLabel: string
  disabled?: boolean
  onSizeBadgerClick?: () => void
}

const SizeBadger = ({ sizeLabel, disabled = false, onSizeBadgerClick = () => {} }: ISizeBadger): JSX.Element => {
  const onSizeChange = (): void => {
    onSizeBadgerClick()
  }
  return <div onClick={onSizeChange} className={disabled
    ? 'inline-block cursor-not-allowed rounded border-dotted border border-white border-opacity-80  pl-2 pr-2 pt-1 pb-1 mr-2 mb-1.5 mt-1.5 text-center opacity-50 line-through'
    : 'inline-block cursor-pointer rounded border-solid border border-white border-opacity-80 pl-2 pr-2 pt-1 pb-1 mr-2 mb-1.5 mt-1.5 text-center'}>{sizeLabel}</div>
}

export default SizeBadger
