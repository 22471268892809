import React, { useEffect, useState } from 'react'
import CartComponent from '../component/CartComponent'
import { useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from './redux/store'
import { fetchCartItems } from './redux/slices/CartSlice'
import LoadingSpinner from '../component/common/LoadingSpinner'

const CartContainer = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>()
  const [customerId] = useState(localStorage.getItem('customerId'))
  const isLoading = useSelector((state: RootState) => state.CartSlice.isLoading)

  useEffect(() => {
    void dispatch(fetchCartItems(customerId ?? ''))
  }, [])

  if (isLoading) {
    return <LoadingSpinner />
  }
  return (
    <CartComponent />
  )
}

export default CartContainer
