import React, { useState } from 'react'
import Button from './Button'
import { useDispatch } from 'react-redux'
import type { AppDispatch } from '../../container/redux/store'
import { insertUserAddress, updateUserAddress } from '../../container/redux/slices/AccountsSlice'
interface Address {
  addressId: string
  address: string
  pincode: string
  state: string
  city: string
  country: string
  __typename: string
}
interface IAddEditAddressProps {
  isEdit: boolean
  address?: Address
  onCancel?: () => void
}

const AddEditAddress = ({ isEdit = false, address, onCancel = (): void => { } }: IAddEditAddressProps): JSX.Element => {
  const [customerId] = useState(localStorage.getItem('customerId') ?? '')
  const [addressName, setAddressName] = useState('')
  const [userMobileNo, setUserMobileNo] = useState('0987653456')
  const [pincode, setPincode] = useState(address?.pincode ?? '')
  const [locality, setLocality] = useState('locality')
  const [mainAddress, setMainAddress] = useState(address?.address ?? '')
  const [city, setCity] = useState(address?.city ?? '')
  const [state, setState] = useState(address?.state ?? '')
  const [landmark, setLandmark] = useState('landmark')
  const [altPhone, setAltPhone] = useState('0987654')
  const [addressType, setAddressType] = useState('Home')
  const [addressId] = useState(address?.addressId ?? '')
  const [country, setCountry] = useState(address?.country ?? '')
  const dispatch = useDispatch<AppDispatch>()
  const countryOptions = [{ countryName: 'India', countryValue: 'India' }]

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setCountry(event.target.value)
  }
  const handleAddressTypeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setAddressType(event.currentTarget.value)
  }

  const handleSaveButtonClick: () => void = (): void => {
    onCancel()
    if (isEdit) {
      void dispatch(updateUserAddress({
        addressId,
        customerId,
        address: mainAddress,
        city,
        state,
        country,
        pincode
      }))
    } else {
      void dispatch(insertUserAddress({
        addressId,
        customerId,
        address: mainAddress,
        city,
        state,
        country,
        pincode
      }))
    }
  }

  return (
    <div className='border border-[#c5b4b4] p-5'>
      <div className='uppercase mb-2'>
        {isEdit ? 'Edit the address' : 'Add a new Address'}
      </div>
      <div className='flex flex-col sm:flex-row py-1'>
        <div className='flex-1 sm:p-3'>
          <label htmlFor="name" className="block mb-2 text-sm font-mediumtext-white">Name</label>
          <input type="text" id="name" className="w-full sm:inline sm:mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5" placeholder={'Eg. Nick Name'} onChange={(e) => { setAddressName(e.target.value) }} value={addressName} required />
        </div>
        <div className='flex-1 sm:p-3'>
          <label htmlFor="Mobile" className="block mb-2 text-sm font-mediumtext-white">Mobile No</label>
          <input type="Number" id="mobile" className="w-full sm:inline sm:mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5" onChange={(e) => { setUserMobileNo(e.target.value) }} placeholder={'Eg. 12345'} value={userMobileNo} required />
        </div>
      </div>
      <div className='flex flex-col sm:flex-row py-1'>
        <div className='flex-1 sm:p-3'>
          <label htmlFor="pincode" className="block mb-2 text-sm font-mediumtext-white">Pincode :</label>
          <input type="pincode" id="pincode" className="w-full sm:inline sm:mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5" onChange={(e) => { setPincode(e.target.value) }} placeholder={'Eg. 700034'} value={pincode} required />
        </div>
        <div className='flex-1 sm:p-3'>
          <label htmlFor="locality" className="block mb-2 text-sm font-mediumtext-white">Locality :</label>
          <input type="text" id="locality" className="w-full sm:inline sm:mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5" onChange={(e) => { setLocality(e.target.value) }} placeholder={'locality'} value={locality} required />
        </div>
      </div>
      <div className='py-1 sm:p-3'>
        <label className="block mb-2 text-sm font-mediumtext-white">Address :</label>
        <textarea id="address" onChange={(e) => { setMainAddress(e.target.value) }} name="address" className='w-full p-1 rounded-lg bg-transparent text-white border border-[#c5b4b4] ' rows={4} cols={50} value={mainAddress} />
      </div>
      <div className='flex flex-col sm:flex-row py-1'>
        <div className='flex-1 sm:p-3'>
          <label htmlFor="city" className="block mb-2 text-sm font-mediumtext-white">City/Town :</label>
          <input type="city" id="text" className="w-full sm:inline sm:mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5" onChange={(e) => { setCity(e.target.value) }} placeholder={'City/Town'} value={city} required />
        </div>
        <div className='flex-1 sm:p-3'>
          <label htmlFor="state" className="block mb-2 text-sm font-mediumtext-white">State :</label>
          <input type="text" id="state" className="w-full sm:inline sm:mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5" onChange={(e) => { setState(e.target.value) }} placeholder={'State'} value={state} required />
        </div>
      </div>
      <div className='flex flex-col sm:flex-row py-1'>
        <div className='flex-1 sm:p-3'>
          <label htmlFor="landmark" className="block mb-2 text-sm font-mediumtext-white">Landmark (optional) :</label>
          <input type="text" id="landmark" className="w-full sm:inline sm:mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5" onChange={(e) => { setLandmark(e.target.value) }} placeholder={'Tell us whats near you ?'} value={landmark} required />
        </div>
        <div className='flex-1 sm:p-3'>
          <label htmlFor="altPhone" className="block mb-2 text-sm font-mediumtext-white">Alternate phone :</label>
          <input type="text" id="altPhone" className="w-full sm:inline sm:mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5" onChange={(e) => { setAltPhone(e.target.value) }} placeholder={'Alternate Phone'} value={altPhone} required />
        </div>
      </div>
      <div className='p-3'>
        <div className='block mb-2 text-sm font-medium text-white'>Country</div>
        <select
          id="gender"
          placeholder={country}
          value={country}
          onChange={handleCountryChange}
          className="bg-transparent py-2 px-4 border border-gray-300 text-lg rounded-lg"
        >
          {countryOptions.map((country) => {
            return <option className='bg-transparent' key={country.countryName} value={country.countryValue}>{country.countryName}</option>
          })}
        </select>
      </div>
      <div className='p-3'>
        <div>Address Type : </div>
        <input type='radio' onChange={handleAddressTypeChange} checked={addressType === 'Office'} value={'Office'}></input>
        <label className='px-2' htmlFor='Office'>Office</label>
        <input type='radio' onChange={handleAddressTypeChange} checked={addressType === 'Home'} value={'Home'}></input>
        <label className='px-2' htmlFor='Home'>Home</label>
      </div>
      <div className='flex flex-col sm:flex-row justify-evenly p-3'>
        <Button type='btn-secondary-small' onButtonClick={handleSaveButtonClick} label='Save' />
        <Button type='btn-secondary-small' onButtonClick={onCancel} label='Cancel' />
      </div>
    </div>
  )
}

export default AddEditAddress
