export default {
  container: [
    'min-w-screen',
    'flex',
    'min-h-screen',
    'bg-primary',
    'flex-col',
    'text-white'
  ].join(' ')
}
