import React from 'react'
import { PlusSizesListComponent } from '../component'
import { type DocumentNode, useQuery } from '@apollo/client'
import {
  GET_PLUS_SIZE_KURTIS,
  GET_PLUS_SIZE_ALIAS,
  GET_PLUS_SIZE_ANARKALIS,
  GET_PLUS_SIZE_COORD_SETS,
  GET_PLUS_SIZE_NAIRA_CUTS
} from '../graphql/queries'
import { CATEGORY_SELECTION_MAP } from '../Constants'
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedCategory } from './redux/slices/PlusSizeListContainerSlice'

const PlusSizesListContainer = (): JSX.Element => {
  const dispatch = useDispatch()
  const selectedCategory = useSelector((state: any) => state.plusSizeListContainerReducer.selectedCategory)
  const query = getSelectedCategoryQuery(selectedCategory)
  const { loading, error, data } = useQuery(query, {
    variables: {
      pageNumber: 1
    }
  })
  const handleChangeSelectedCategoryCallback = (selectedCategory: typeof CATEGORY_SELECTION_MAP): void => {
    dispatch(setSelectedCategory(selectedCategory))
  }

  if (error != null) {
    alert('error fetching plus size details')
  }

  return (
    <PlusSizesListComponent
      selectedCategoryData={data}
      selectedCategory={selectedCategory}
      setSelectedCategory={handleChangeSelectedCategoryCallback}
      isLoading={loading}
    />
  )
}

const getSelectedCategoryQuery = (selectedCategory = CATEGORY_SELECTION_MAP): DocumentNode => {
  if (selectedCategory.kurtis) {
    return GET_PLUS_SIZE_KURTIS
  }
  if (selectedCategory.aliaCuts) {
    return GET_PLUS_SIZE_ALIAS
  }
  if (selectedCategory.anarkalis) {
    return GET_PLUS_SIZE_ANARKALIS
  }
  if (selectedCategory.coOrds) {
    return GET_PLUS_SIZE_COORD_SETS
  }
  if (selectedCategory.nairaCuts) {
    return GET_PLUS_SIZE_NAIRA_CUTS
  }
  return GET_PLUS_SIZE_KURTIS
}

export default PlusSizesListContainer
