const CATEGORY_SELECTION_MAP = {
  kurtis: false,
  nairaCuts: false,
  aliaCuts: false,
  anarkalis: false,
  coOrds: false
}
const SERVER_URI = 'https://api.jasavya.com'
const SERVER_URI_PATH = 'https://api.jasavya.com/'

export { CATEGORY_SELECTION_MAP, SERVER_URI, SERVER_URI_PATH }
