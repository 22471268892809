import { gql } from '@apollo/client'

const GET_USER_TOKEN_MUTATION = gql`
  mutation loginMutation{
    login(input:{
      password:"password@123",
      email: "nayaEMail@gamil.com"
    }){
      success{
        customerId
      }
      error{
        errorCode
        errorMessage
      }
    }
  }
`
const UPDATE_USER_MUTATION = gql`
mutation updateProfile($customerId: String, $userName:String, $userPhone:String, $userGender:Gender ) {
  updateProfile(input: { 
    customerId: $customerId, 
    name: $userName,
    phone: $userPhone
    gender: $userGender
  }) {
    isSuccess
    error{
      errorCode
      errorMessage
    }
  }
}
`

const GET_USER_DETAILS_QUERY = gql`
query getCustomer($customerId:CustomerId!){
  customer(input: {
    customerId: $customerId
  }){
    customerId
    profile{
      phone
      name
      email
      gender
      addresses{
        addressId
        address
        pincode
        state
        city
        country
      }
    }
  }
}`

const UPDATE_ADDRESS_MUTATION = gql`
mutation updateAddress($addressId: String!, $customerId: CustomerId!, $address: String, $city: String, $state: String, $country: String, $pincode: String){
  updateAddress(input:{
    addressId: $addressId,
    customerId: $customerId,
    address: $address,
   city: $city,
    state: $state,
    country: $country,
    pincode: $pincode
  }){
    isSuccess
    error{
      errorCode
      errorMessage
    }
  }
}`

const INSERT_ADDRESS_MUTATION = gql`
mutation createAddress($customerId: CustomerId!, $address: String!, $city: String!, $state: String!, $country: String!, $pincode: String!){
  createAddress(input:{
    customerId: $customerId,
    address: $address,
   city: $city,
    state: $state,
    country: $country,
    pincode: $pincode
  }){
    success{
      addressId
    }
    error{
      errorCode
      errorMessage
    }
  }
}
`
const DELETE_ADDRESS_MUTATION = gql`
mutation deleteAddress($addressId: String!, $customerId: CustomerId!){
  deleteAddress(input:{
    addressId: $addressId,
    customerId: $customerId,
  }){
    isSuccess
    error{
      errorCode
      errorMessage
    }
  }
}`

export {
  GET_USER_TOKEN_MUTATION,
  GET_USER_DETAILS_QUERY,
  UPDATE_USER_MUTATION,
  UPDATE_ADDRESS_MUTATION,
  INSERT_ADDRESS_MUTATION,
  DELETE_ADDRESS_MUTATION
}
