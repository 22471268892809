import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import graphqlClient from '../../../helper/ApolloClient'
import { ADD_TO_CART_MUTATION, GET_CART_ITEMS_QUERY, UPDATE_CART_ITEM_MUTATION } from '../../../graphql/cartQueries'
import { toast } from 'react-toastify'

interface CartItem {
  cartItemId: string
  fashionItemId: string
  itemType: string
  itemQuantity: number
  size: string
  item: {
    name: string
    primaryImage: {
      imageUrl: string
    }
    pricePerPiece: string
  }
}

interface ICartData {
  totalQuantity: number
  cartItems: CartItem[]
  isLoading: boolean
  error: string | null
}

const initialState: ICartData = {
  totalQuantity: 0,
  cartItems: [],
  isLoading: false,
  error: null
}

export const fetchCartItems = createAsyncThunk('cart/fetchCartItems', async (userId: string) => {
  const { data: cartData } = await graphqlClient.query({
    query: GET_CART_ITEMS_QUERY,
    variables: { customerId: userId },
    fetchPolicy: 'no-cache'
  })
  return cartData.getCart
})

export const updateCartItem = createAsyncThunk('cart/updateCartItem', async ({ userId, cartItemId, shouldDeleteItem, itemQuantity }: {
  userId: string
  cartItemId: string
  shouldDeleteItem: boolean
  itemQuantity: number
}, { dispatch }) => {
  const { data: updateCartData } = await graphqlClient.mutate({
    mutation: UPDATE_CART_ITEM_MUTATION,
    variables: { customerId: userId, cartItemId, shouldDelete: shouldDeleteItem, itemQuantity },
    fetchPolicy: 'no-cache'
  })
  if (updateCartData?.updateCartItem?.isSuccess === true) {
    if (shouldDeleteItem) {
      toast.success('Item deleted from cart succesfuly')
    } else {
      toast.success('Item quantity updated succesfuly')
    }
  } else {
    toast.error('Oops! unable to update the cart')
  }
  await dispatch(fetchCartItems(userId))
  return updateCartData.updateCartItem
})

export const addItemsToCart = createAsyncThunk('cart/addItemsToCart', async ({ userId, itemId, itemType, itemSize, itemQuantity }: {
  userId: string
  itemId: string
  itemType: string
  itemSize: string
  itemQuantity: number
}, { dispatch }) => {
  const { data: addToCartData } = await graphqlClient.mutate({
    mutation: ADD_TO_CART_MUTATION,
    variables: { customerId: userId, itemId, itemType, itemSize, itemQuantity },
    fetchPolicy: 'no-cache'
  })
  if (addToCartData.addToCart?.error?.errorMessage.length > 0) {
    toast.error(addToCartData.addToCart?.error?.errorMessage)
  } else {
    toast.success('Item Added to Cart!')
  }
  return addToCartData.addToCart
})

const CartSlice = createSlice({
  name: 'CartSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.totalQuantity = action.payload.totalQuantity
        state.cartItems = action.payload.cartItems
        state.isLoading = false
        state.error = null
      })
      .addCase(fetchCartItems.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? 'Failed to fetch cart items'
      })
      .addCase(updateCartItem.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(updateCartItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
      })
      .addCase(updateCartItem.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? 'Failed to update cart items'
      })
      .addCase(addItemsToCart.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(addItemsToCart.fulfilled, (state, action) => {
        state.isLoading = false
        state.error = null
      })
      .addCase(addItemsToCart.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message ?? 'Failed to add items to cart'
      })
  }
})

export default CartSlice.reducer
