import React, { useEffect, useState } from 'react'
import Button from './common/Button'
import type { AppDispatch, RootState } from '../container/redux/store'
import LoadingSpinner from './common/LoadingSpinner'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from '@apollo/client'
import { UPDATE_USER_MUTATION } from '../graphql/userQueries'
import { toast } from 'react-toastify'
import { fetchUserDetails } from '../container/redux/slices/AccountsSlice'

const UserProfileComponent = (): JSX.Element => {
  const userData = useSelector((state: RootState) => state.AccountsSlice.profileData)
  const isLoadingAccounts = useSelector((state: RootState) => state.AccountsSlice.isLoading)
  const [userName, setUserName] = useState(userData?.profile?.name)
  const [userEmail, setUserEmail] = useState(userData?.profile?.email)
  const [userMobile, setUserMobile] = useState(userData?.profile.phone)
  const [editInfo, setEditInfo] = useState(false)
  const [userGender, setSelectedUserGender] = useState<string>(userData.profile.gender)
  const genderSelectorOptions = [{ genderName: 'Male', genderValue: 'MALE' },
    { genderName: 'Female', genderValue: 'FEMALE' },
    { genderName: 'Other', genderValue: 'OTHER' },
    { genderName: 'Prefer not to say', genderValue: 'PREFER_NOT_TO_SAY' }
  ]
  const [updateUser, { loading: userUpdationLoading, data: userProfileUpdationData }] = useMutation(UPDATE_USER_MUTATION)
  const handleUserGenderChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setSelectedUserGender(event.target.value)
  }
  const dispatch = useDispatch<AppDispatch>()
  const [customerId] = useState(localStorage.getItem('customerId'))
  // need to provide error Toast for failed user profile update

  if (userProfileUpdationData != null) {
    if (userProfileUpdationData.updateProfile.isSuccess === true) {
      toast.success('Successfully updated user details.')
      void dispatch(fetchUserDetails(customerId ?? ''))
    }
    userProfileUpdationData.updateProfile.isSuccess = false
  }

  const enableUserInfoEdit = (): void => {
    setEditInfo(!editInfo)
  }
  const handleFirstNameChange = (firstName: string): void => {
    setUserName(firstName)
  }

  const handleUserEmailChange = (email: string): void => {
    setUserEmail(email)
  }

  const handleUserMobileChange = (mobileNo: string): void => {
    setUserMobile(mobileNo)
  }

  const resetFields = (): void => {
    setUserName(userData.profile.name)
    setSelectedUserGender(userData.profile.gender)
    setUserEmail(userData.profile.email)
    setUserMobile(userData.profile.phone)
  }

  const handleCancel = (): void => {
    resetFields()
    setEditInfo(!editInfo)
  }

  const handleUpdateUserProfile = (): void => {
    setEditInfo(!editInfo)
    void updateUser({
      variables: {
        customerId: localStorage.getItem('customerId'),
        userName,
        userPhone: userMobile,
        userGender
      }
    })
  }

  useEffect(() => {
    resetFields()
  }, [userData.profile])

  if (isLoadingAccounts || userUpdationLoading) {
    return <LoadingSpinner />
  }
  // ### set color of dropdown to tranparent
  return (
    <div><span className='hidden sm:block text-2xl'>
      Profile :
    </span>
      <div className='p-5'>
        Personal Information <span className='mr-3 cursor-pointer text-[#f3dc92]'>{!editInfo ? (<span onClick={() => { enableUserInfoEdit() }}>Edit</span>) : (<span onClick={() => { handleCancel() }}>Cancel</span>)}</span>
        <span>{editInfo && <Button onButtonClick={handleUpdateUserProfile} label='Save' type='btn-secondary-micro' />}</span>
        <div className='mt-4 mb-4'>
          <label htmlFor="name" className="block mb-2 text-sm font-medium text-white">Name</label>
          <input type="text" id="firstName" disabled={!editInfo} className="sm:inline mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full sm:w-1/3 p-2.5" placeholder={userName} value={userName} onChange={(e) => { handleFirstNameChange((e.target.value)) }} required />
          <div className='py-2'>
            <div className='block mb-2 text-sm font-medium text-white'>Your Gender :</div>
            <select
              id="gender"
              placeholder={userGender}
              value={userGender}
              onChange={handleUserGenderChange}
              className="bg-transparent py-2 px-4 border border-gray-300 text-lg rounded-lg"
              disabled={!editInfo}
            >
              {genderSelectorOptions.map((gender) => {
                return <option className='bg-transparent' key={gender.genderValue} value={gender.genderValue}>{gender.genderName}</option>
              })}
            </select>
          </div>
          <div>
            <div className='py-2'>Email :
            </div>
            <input type="email" id="email" disabled={!editInfo} className="sm:inline mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full sm:w-1/3 p-2.5" placeholder={userEmail} value={userEmail} onChange={(e) => { handleUserEmailChange((e.target.value)) }} required />
          </div>
          <div>
            <div className='py-2'>Mobile No :
            </div>
            <input type="number" id="mobileNo" disabled={!editInfo} className="sm:inline mr-3 sm:mr-3 bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full sm:w-1/3 p-2.5" placeholder={userMobile} value={parseInt(userMobile)} onChange={(e) => { handleUserMobileChange((e.target.value)) }} required />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserProfileComponent
