import React, { useMemo } from 'react'
import CartItemTile from './common/CartItemTile'
import { MdCurrencyRupee } from 'react-icons/md'
import Button from './common/Button'
import { useSelector } from 'react-redux'
import type { RootState } from '../container/redux/store'
import EmptyCart from './common/EmptyCart'

const CartComponent = (): JSX.Element => {
  const cartItemData = useSelector((state: RootState) => state.CartSlice)

  const cartTotalAmmount = useMemo(() => {
    return cartItemData.cartItems.reduce((total, cartItem) => {
      return total + (parseInt(cartItem.item.pricePerPiece) * cartItem.itemQuantity)
    }, 0)
  }, [cartItemData.cartItems])
  const cartItemTiles = <div>
    {cartItemData.cartItems.map((cartItem) => {
      return <div key={cartItem.cartItemId}><CartItemTile itemData={cartItem} /></div>
    })}
  </div>
  return (
    <div className='cart-container '>
      <div className='text-2xl m-5'>My cart<span className='italic border-l-[1px] pl-2 ml-2 text-xl'>{cartItemData.totalQuantity}</span></div>
      {(cartItemData.totalQuantity !== 0)
        ? <div className='block sm:flex m-5 border-t-[1px] mt-2 border-[#d8cdcd]'>
          <div className='cart-left w-full sm:w-[60%] '>
            <div className=''>
              {cartItemTiles}
            </div>
          </div>
          <div className='cart-right w-full sm:w-[40%] pl-0 sm:px-10 py-5 sm:py-2'>
            <div className='cart-order-summary-text text-2xl' >ORDER SUMMARY</div>
            <div className='flex justify-between px-2'>
              <span>Subtotal</span>
              <span><MdCurrencyRupee className='inline-block' />{cartTotalAmmount}</span>
            </div>
            <div className='flex justify-between px-2 pb-2 border-[#d8cdcd] border-b-[1px]'>
              <span>Shipping</span>
              <span>its on us !</span>
            </div>
            <div className='flex justify-between px-2 py-1'>
              <span><strong>Order Total</strong></span>
              <span><MdCurrencyRupee className='inline-block ' />{cartTotalAmmount}</span>
            </div>
            <div className='text-center w-full py-5'>
              <Button label='CHECKOUT' type='btn-primary' />
            </div>
          </div>
        </div>
        : <EmptyCart />}
      <div>
      </div>
    </div>
  )
}

export default CartComponent
