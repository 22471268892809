import React, { useEffect } from 'react'
import ItemsListGrid from './common/ItemsListGrid'
import { clsx } from 'clsx'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import { CATEGORY_SELECTION_MAP } from '../Constants'
import LoadingSpinner from './common/LoadingSpinner'

const PlusSizesListComponent = (
  {
    selectedCategoryData,
    selectedCategory,
    setSelectedCategory,
    isLoading
  }: {
    selectedCategoryData: Record<string, any> | undefined
    selectedCategory: Record<string, any>
    setSelectedCategory: (selectedCategory: any) => void
    isLoading: boolean
  }): JSX.Element => {
  useEffect(() => {
    if (selectedCategory === CATEGORY_SELECTION_MAP) {
      setSelectedCategory({
        ...CATEGORY_SELECTION_MAP,
        kurtis: true
      })
    }
  })

  const getCategoryList = (): Array<Record<string, any>> => {
    if (selectedCategory.kurtis === true) {
      return selectedCategoryData?.kurtis?.kurtisList
    }
    if (selectedCategory.anarkalis === true) {
      return selectedCategoryData?.anarkalis?.anarkalisList
    }
    if (selectedCategory.coOrds === true) {
      return selectedCategoryData?.coOrds?.coOrdsList
    }
    if (selectedCategory.nairaCuts === true) {
      return selectedCategoryData?.nairaCuts?.nairaCutsList
    }
    if (selectedCategory.aliaCuts === true) {
      return selectedCategoryData?.alias?.aliasList
    }
    return selectedCategoryData?.kurtis?.kurtisList
  }

  const categoryFilters = [
    {
      key: 'kurtis',
      heading: <p
        onClick={() => {
          setSelectedCategory({
            ...CATEGORY_SELECTION_MAP,
            kurtis: true
          })
        }}
        className={clsx('text-xs sm:text-base pb-2 pt-2 pl-1 sm:pl-2 cursor-pointer', selectedCategory.kurtis === true ? 'bg-white/25' : '')}>Kurtis</p>
    },
    {
      key: 'nairaCuts',
      heading: <p
        onClick={() => {
          setSelectedCategory({
            ...CATEGORY_SELECTION_MAP,
            nairaCuts: true
          })
          // getSelectedCategoryData(CATEGORIES.nairaCuts)
        }}
        className={clsx('text-xs sm:text-base pb-2 pt-2 pl-1 sm:pl-2 cursor-pointer', selectedCategory.nairaCuts === true ? 'bg-white/25' : '')}>Naira Cuts</p>
    },
    {
      key: 'aliaCuts',
      heading: <p
        onClick={() => {
          setSelectedCategory({
            ...CATEGORY_SELECTION_MAP,
            aliaCuts: true
          })
        }}
        className={clsx('text-xs sm:text-base pb-2 pt-2 pl-1 sm:pl-2 cursor-pointer', selectedCategory.aliaCuts === true ? 'bg-white/25' : '')}>Alia Cuts</p>
    },
    {
      key: 'anarkalis',
      heading: <p
        onClick={() => {
          setSelectedCategory({
            ...CATEGORY_SELECTION_MAP,
            anarkalis: true
          })
        }}
        className={clsx('text-xs sm:text-base pb-2 pt-2 pl-1 sm:pl-2 cursor-pointer', selectedCategory.anarkalis === true ? 'bg-white/25' : '')}>Anarkalis</p>
    },
    {
      key: 'coOrdSets',
      heading: <p
        onClick={() => {
          setSelectedCategory({
            ...CATEGORY_SELECTION_MAP,
            coOrds: true
          })
        }}
        className={clsx('text-xs sm:text-base pb-2 pt-2 pl-1 sm:pl-2 cursor-pointer', selectedCategory.coOrds === true ? 'bg-white/25' : '')}>Co-Ord Sets</p>
    }
  ]
  return (
    <div>
      <div className='flex flex-col mt-10'>
        <div className='flex items-center justify-center w-full mb-6 font-raleway text-xl sm:text-2xl lg:text-4xl xl:text-5xl'>
          Wear your crown. Ditch the scale.
        </div>
        <div className='flex'>
          <div className='w-1/6 h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File85.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
          <div className='w-1/6 h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File89.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
          <div className='w-1/6 h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File119.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
          <div className='w-1/6 h-20  sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File114.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
          <div className='w-1/6 h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File91.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
          <div className='w-1/6 h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File109.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
        </div>
        <div className='flex'>
          <div className='w-1/6  h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File103.jpeg" className='bg-cover object-cover w-full h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96' />
          </div>
          <div className='w-1/6  h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File106.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
          <div className='w-1/6  h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File121.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
          <div className='w-1/6  h-20  sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File102.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
          <div className='w-1/6  h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File46.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
          <div className='w-1/6  h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96'>
            <img src="http://api.mahodayaa.com/images/File95_PtgED9Y.jpeg" className='bg-cover object-cover h-20 sm:h-32 md:h-48 lg:h-72 xl:h-80 2xl:h-92 3xl:h-96 w-full' />
          </div>
        </div>
        <div className='flex items-center justify-center w-full mt-3 mb-3 font-raleway text-xl sm:text-2xl lg:text-4xl xl:text-5xl'>
          Swing in Style
        </div>
      </div>
      <div className='flex'>
        <div className='w-2/7 sm:w-1/5 h-96'>
          <h2 className='m-2 font-bold text-xs'>FILTER BY</h2>
          <div className='m-2 bg-navbar'>
            <Accordion allowZeroExpanded preExpanded={['categories']}>
              <AccordionItem uuid="categories">
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <div className='w-full bg-navbar pt-3 pb-3 pl-1 pr-1 text-xs sm:text-base font-bold'>{'Categories'}</div>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {categoryFilters.map((item) => (
                    <p key={item.key}>{item.heading}</p>
                  ))}
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        {isLoading
          ? <div className='flex w-full items-center justify-center'>
            <LoadingSpinner />
          </div>
          : <div className='w-4/5'>
            <ItemsListGrid itemList={getCategoryList()} shouldSliceForSmallDevices={false} />
          </div>
        }
      </div>
    </div>
  )
}

export default PlusSizesListComponent
