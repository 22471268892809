import React, { useState } from 'react'
import ImageGallery from 'react-image-gallery'
import { GiLargeDress } from 'react-icons/gi'
import { MdCurrencyRupee } from 'react-icons/md'
import { RxRulerHorizontal } from 'react-icons/rx'
import SizeBadger from './common/SizeBadger'
import Button from './common/Button'
import Collapsible from './common/Collapsible'
import SizeChartComponent from './SizeChartComponent'
import { SERVER_URI } from '../Constants'
import { useDispatch } from 'react-redux'
import { addItemsToCart } from '../container/redux/slices/CartSlice'
import type { AppDispatch } from '../container/redux/store'

interface ICarouselImage {
  original: string
  thumbnail: string
}

interface IProductDetails {
  itemData: Record<string, any>
}
const ProductDetailsComponent = ({ itemData }: IProductDetails): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>()
  const [customerId] = useState(localStorage.getItem('customerId'))
  const images: ICarouselImage[] = []
  const [quantity, setQuantity] = useState(1)
  const [showSizeChart, setShowSizeChart] = useState(false)
  const [selectedSize, setSelectedSize] = useState(itemData?.sizes[0].size)
  const product = {
    title: 'Loading...',
    description: 'Loading..'
  }
  const maxQuantity = 100
  const minQuantity = 1
  let discountPercentage = '0'
  const calDiscountPercentage = (mrp: number, sellingPrice: number): string => {
    return ((mrp - sellingPrice) * 100 / mrp).toFixed()
  }
  const handleDecrementQuantity = (): void => {
    if (quantity > minQuantity) {
      setQuantity(quantity => quantity - 1)
    }
  }

  const handleIncrementQuantity = (): void => {
    if (quantity < maxQuantity) {
      setQuantity(quantity => quantity + 1)
    }
  }

  const handleShowSizeChart = (): void => {
    setShowSizeChart(!showSizeChart)
  }

  images.push({
    original: SERVER_URI.concat('/').concat(itemData?.primaryImage?.imageUrl),
    thumbnail: SERVER_URI.concat('/').concat(itemData?.primaryImage?.imageUrl)
  })

  itemData?.additionalImages?.forEach((image: any) => {
    images.push({
      original: SERVER_URI.concat('/').concat(image?.imageUrl),
      thumbnail: SERVER_URI.concat('/').concat(image?.imageUrl)
    })
  })
  if (itemData != null) {
    product.title = itemData.name
    product.description = itemData.description
    discountPercentage = calDiscountPercentage(itemData.pricePerPiece, itemData.discountedPricePerPiece)
  }

  const addToCart = (): void => {
    void dispatch(addItemsToCart({
      userId: customerId ?? '',
      itemId: itemData.id,
      itemQuantity: quantity,
      itemSize: selectedSize,
      itemType: itemData.__typename.toUpperCase()
    }))
  }

  const handleSizeSelection = (size: string): void => {
    setSelectedSize(size)
  }
  return (
    <div>
      <div className='block sm:flex p-5 text-white'>
        <div className="w-100 sm:w-1/2 lg:w-1/3">
          <ImageGallery items={images} thumbnailPosition="left" />
          <div className='hidden sm:block md:hidden'>
            <div className='flex-col product-price block sm:flex pb-3 mt-4 text-2xl sm:text-3xl'>
              <div className='mb-2 sm:mb-0'>
                <div className='mr-2 pr-2'>MRP <MdCurrencyRupee className='inline-block' />: <del>{itemData.pricePerPiece}</del> <strong>{itemData.discountedPricePerPiece}</strong></div>
                <div className='text-sm sm:text-base '>(inclusive of all taxes)</div>
              </div>
              <div className='p-1 block w-fit h-fit sm:inline-block bg-red-500 sm:text-sm'>FLAT {discountPercentage}% OFF</div>
            </div>
          </div>
          {itemData?.sizes !== undefined
            ? <div className='hidden sm:block md:hidden mt-2 mb-2'>
              <span className='block text-2xl sm:text-3xl'>Size : {selectedSize}</span>
              <div className='flex flex-wrap mt-1 mb-1 sm:mt-2 sm:mb-2'>
                {itemData?.sizes?.map((size: {
                  size: string
                  isInStock: boolean
                }) => {
                  return <SizeBadger onSizeBadgerClick={() => { handleSizeSelection(size.size) }} sizeLabel={size.size} key={size.size} disabled={!(size.isInStock)} />
                })}

              </div>
              <button onClick={() => { handleShowSizeChart() }}>
                <span>*Size Chart</span>
                <RxRulerHorizontal className='inline-block ml-2' size={25} />
              </button>
            </div>
            : null}
        </div>
        <div className='p-5 sm:pl-5 sm:pt-1 w-100 sm:w-1/2 lg:w-2/3'>
          <div className='flex items-center justify-center sm:justify-normal pb-2'>
            <GiLargeDress size={'2em'} /><span className='pl-1'>{itemData.__typename}</span>
          </div>
          <div className='text-3xl sm:text-3xl pb-1 border-b-[1px] border-solid border-yellow-100'>{product.title}</div>
          <p className='mt-1 text-sm sm:text-base'>{product.description}</p>
          <div className='sm:hidden md:block'>
            <div className='product-price block sm:flex pb-3 mt-4 text-2xl sm:text-3xl'>
              <div className='mb-2 sm:mb-0'>
                <div className='mr-2 pr-2'>MRP <MdCurrencyRupee className='inline-block' />: <del>{itemData?.pricePerPiece}</del> <strong>{itemData?.discountedPricePerPiece}</strong></div>
                <div className='text-sm sm:text-base '>(inclusive of all taxes)</div>
              </div>
              <div className='p-1 block w-fit h-fit sm:inline-block bg-red-500 text-lg sm:text-xl'>FLAT {discountPercentage}% OFF</div>
            </div>
          </div>
          <div className='mt-4 mb-4'>
            <label htmlFor="pinCode" className="block mb-2 text-sm font-medium text-white">Enter Delivery Pincode</label>
            <input type="number" id="pinCode" className="bg-transparent border border-gray-300 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 sm:w-2/3 md:w-1/2 lg:w-1/4 p-2.5" placeholder="eg, 201001" required />
          </div>
          {itemData?.sizes !== undefined
            ? <div className='sm:hidden md:block mt-2 mb-2'>
              <span className='block text-2xl sm:text-3xl'>Size : {selectedSize}</span>
              <div className='flex flex-wrap mt-1 mb-1 sm:mt-2 sm:mb-2'>
                {itemData?.sizes?.map((size: {
                  size: string
                  isInStock: boolean
                }) => {
                  return <SizeBadger onSizeBadgerClick={() => { handleSizeSelection(size.size) }} sizeLabel={size.size} key={size.size} disabled={!(size.isInStock)} />
                })}

              </div>
              <button onClick={() => { handleShowSizeChart() }}>
                <span>*Size Chart</span>
                <RxRulerHorizontal className='inline-block ml-2' size={25} />
              </button>
            </div>
            : null}
          <div className='quantity'>
            <span className='block text-2xl sm:text-3xl mb-2'>Quantity :</span>
            <button className='p-1 w-9 border border-opacity-50 border-solid border-white' onClick={() => { handleDecrementQuantity() }}>-</button>
            <div className='p-1 inline-block w-10 text-center bg-transparent border border-opacity-50 border-solid border-white' >{quantity}</div>
            <button className='p-1 w-9 border border-opacity-50 border-solid border-white' onClick={() => { handleIncrementQuantity() }}>+</button>
          </div>
          <div className='cta mt-4 sm:w-[75%] text-center sm:flex-wrap sm:flex sm:justify-evenly'>
            <Button label='Buy Now' type='btn-primary' />
            <Button label='Add to cart' type='btn-primary' onButtonClick={addToCart} />
          </div>

        </div>
      </div>
      <div className='border-t-[1px] border-s-white'>
        <Collapsible isBodyHidden={false} header='PRODUCT DETAILS' body={<p>
          {itemData.description}
        </p>} />
        <Collapsible isBodyHidden={true} header='SHIPPING & RETURNS' body={<p>All products are shipped within 24 hours of placing the order. A standard date of delivery shall be provided in the order confirmation email. We also provide an easy Exchange/Return Policy for all garments except Accessories. For details visit Shipping & Returns page.</p>} />
      </div>
      {showSizeChart && <SizeChartComponent showSizeChart={handleShowSizeChart} />}
    </div>
  )
}

export default ProductDetailsComponent
