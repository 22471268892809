export default {
  header: ['inline-block', 'w-full', 'bg-gradient-to-b from-logo from-95% to-white'].join(' '),
  titleBar: ['w-full', 'flex'].join(' '),
  leading: ['flex', 'w-full', 'lg:mb-1'].join(' '),
  leadingTitleContainer: ['flex', 'justify-between', 'w-full'].join(' '),
  title: ['sm:p-3'].join(' '),
  titleHeading: [
    'mt-4',
    'font-raleway',
    'text-2xl',
    'text-text-white',
    'sm:mb-2',
    'sm:text-3xl',
    'lg:text-4xl',
    'xl:text-5xl',
    '2xl:text-6xl'
  ].join(' '),
  titleSubHeading: [
    'font-lato',
    'inline-block',
    'p-2',
    'text-xxs',
    'font-extralight',
    'text-white',
    'sm:text-sm',
    'md:text-lg'
  ].join(' ')
}
