import React from 'react'
import { HeroComponent, Showcase } from '../component'
import appStyles from './common/styles/AppStyles'
interface IHomeComponent {
  kurtisList: object[]
  anarkaliList: object[]
  coordsList: object[]
  nairaList: object[]
  aliasList: object[]
  unstitchedSuitsList: object[]
}
const HomeComponent = ({ kurtisList, anarkaliList, coordsList, nairaList, aliasList, unstitchedSuitsList }: IHomeComponent): JSX.Element => {
  return (
    <div className={appStyles.container}>
      <HeroComponent />
      <Showcase kurtisList={kurtisList} anarkaliList={anarkaliList} coordsList={coordsList} nairaList={nairaList} aliasList={aliasList} unstitchedSuitsList={unstitchedSuitsList} />
    </div>
  )
}

export default HomeComponent
