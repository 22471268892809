import React from 'react'
import heroComponentStyles from './styles/HeroComponentStyles'

const HeroComponent = (): JSX.Element => {
  return (
        <div className={heroComponentStyles.heroComponent}>
            <div className={heroComponentStyles.imageContainer}>
                <img
                    className={heroComponentStyles.image}
                    src={require('../assets/images/optimized/item_images/File103.jpeg')}
                />
                <img
                    className={heroComponentStyles.image}
                    src={require('../assets/images/optimized/item_images/File59.jpeg')}
                />
                <img
                    className={heroComponentStyles.image}
                    src={require('../assets/images/optimized/item_images/File101.jpeg')}
                />
                <div className={heroComponentStyles.heroTagLine}>
                    <pre
                        className={
                            heroComponentStyles.heroTagLineHighlightedWords
                        }
                    >
                        TREND{' '}
                    </pre>{' '}
                    setter{' '}
                    <pre
                        className={
                            heroComponentStyles.heroTagLineHighlightedWords
                        }
                    >
                        {' '}
                        SALE{' '}
                    </pre>{' '}
                    is now
                    <pre
                        className={
                            heroComponentStyles.heroTagLineHighlightedWords
                        }
                    >
                        {' '}
                        LIVE{' '}
                    </pre>
                </div>
                <div className={heroComponentStyles.heroSubTagLine}>
                    <p>New arrivals! Hurry!</p>
                </div>
            </div>
        </div>
  )
}

export default HeroComponent
