import { gql } from '@apollo/client'

const GET_CART_ITEMS_QUERY = gql`query getCart($customerId: CustomerId!){
  getCart(input:{
    customerId: $customerId
  }){
    totalQuantity
    cartItems{
      cartItemId
      fashionItemId
      itemType
      itemQuantity
      size
      item{
        ... on Alia{
          name
          primaryImage{
            imageUrl
          }
          pricePerPiece
        }
      }
    }
  }
}`

const UPDATE_CART_ITEM_MUTATION = gql`mutation updateCartItem($customerId: CustomerId!, $cartItemId: String!, $itemQuantity: Int, $shouldDelete: Boolean ){
  updateCartItem(input:{
    cartItemId: $cartItemId,
    customerId: $customerId,
    shouldDelete: $shouldDelete,
itemQuantity: $itemQuantity
  }){
    isSuccess
    error{
      errorCode
      errorMessage
    }
  }
}`

const ADD_TO_CART_MUTATION = gql`mutation addToCart($customerId:CustomerId!,$itemId: String!, $itemType:FashionItemType!, $itemSize:FashionItemSize!, $itemQuantity:Int!){
  addToCart(input:{
    itemId: $itemId
    itemType: $itemType
    itemSize: $itemSize
    itemQuantity: $itemQuantity
    customerId: $customerId
  }){
    success{
      cartItemId
    }
    error{
      errorCode
      errorMessage
    }
  }
}`

export {
  GET_CART_ITEMS_QUERY,
  UPDATE_CART_ITEM_MUTATION,
  ADD_TO_CART_MUTATION
}
