import React from 'react'
import { ProductDetailsComponent } from '../component'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import LoadingSpinner from '../component/common/LoadingSpinner'
import {
  GET_DATA_ALIA,
  GET_DATA_ANARKALI,
  GET_DATA_COORD,
  GET_DATA_KURTI,
  GET_DATA_NAIRACUT,
  GET_DATA_UNSTITCHED_SUIT
} from '../graphql/queries'
const ProductDetailsContainer = (): JSX.Element => {
  const itemQueryMap = new Map([
    ['Kurti', GET_DATA_KURTI],
    ['Alia', GET_DATA_ALIA],
    ['Anarkali', GET_DATA_ANARKALI],
    ['CoOrd', GET_DATA_COORD],
    ['NairaCut', GET_DATA_NAIRACUT],
    ['UnStitchedSuit', GET_DATA_UNSTITCHED_SUIT]
  ])

  const queryParamItemTypeToItemTypeMap: Record<string, string> = {
    UnStitchedSuit: 'unstitchedSuit',
    Kurti: 'kurti',
    Anarkali: 'anarkali',
    Alia: 'alia',
    CoOrd: 'coOrd',
    NairaCut: 'nairaCut'
  }

  const getDataItemType = (itemType: string): string => {
    return queryParamItemTypeToItemTypeMap[itemType]
  }

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const itemType = queryParams.get('type') ?? ''
  const itemId = parseInt(queryParams.get('id') ?? '0')
  const query = itemQueryMap.get(itemType)
  const { loading, data } = useQuery(query ?? GET_DATA_KURTI, {
    variables: { itemId }
  })
  if (loading) {
    return <LoadingSpinner />
  }
  return (
    <ProductDetailsComponent itemData={data[getDataItemType(itemType)]} />
  )
}

export default ProductDetailsContainer
