import React, { useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import AddressTile from './common/AddressTile'
import AddEditAddress from './common/AddEditAddress'
import { useSelector } from 'react-redux'
import type { RootState } from '../container/redux/store'
import LoadingSpinner from './common/LoadingSpinner'

const AddressComponent = (): JSX.Element => {
  const addressList = useSelector((state: RootState) => state.AccountsSlice.addressData)
  const isLoading = useSelector((state: RootState) => state.AccountsSlice.isLoading)
  const [showAddEditAddress, setShowAddEditAddress] = useState(false)
  const handleShowAddEditAddress = (): void => {
    setShowAddEditAddress(true)
  }

  if (isLoading) {
    return <LoadingSpinner/>
  }

  return (
    <div>
      <span className='text-2xl'>My Address :</span>
      <div className='p-5'>
        {!showAddEditAddress && <div onClick={() => { handleShowAddEditAddress() }} className='cursor-pointer border-[1px] border-[#c5b4b4] p-3 mb-7'><AiOutlinePlus className='inline' /> Add a New Address</div>}
        {showAddEditAddress && <AddEditAddress onCancel={() => { setShowAddEditAddress(false) }} isEdit={false} />}
        {addressList.map((address) => {
          return <AddressTile key={address.addressId} address={address} />
        })}
      </div>
    </div>
  )
}

export default AddressComponent
