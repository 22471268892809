import React, { useEffect, useState } from 'react'
import buttonStyles from './styles/ButtonStyles'

interface IButton {
  label: string
  type: string
  onButtonClick?: () => void
}

const Button = ({ label, type = 'btn-primary', onButtonClick = () => { } }: IButton): JSX.Element => {
  const [buttonClass, setButtonClass] = useState('')
  useEffect(() => {
    if (type === 'btn-primary') setButtonClass(buttonStyles.btnPrimary)
    if (type === 'btn-secondary-small') setButtonClass(buttonStyles.btnSecondarySmall)
    if (type === 'btn-secondary-micro') setButtonClass(buttonStyles.btnSecondaryMicro)
  }, [])
  return (
    <button onClick={onButtonClick} className={buttonClass}>{label}</button>
  )
}

export default Button
