import { configureStore } from '@reduxjs/toolkit'
import plusSizeListContainerReducer from './slices/PlusSizeListContainerSlice'
import AccountsSliceReducer from './slices/AccountsSlice'
import CartSliceReducer from './slices/CartSlice'

export const store = configureStore({
  reducer: {
    plusSizeListContainerReducer,
    AccountsSlice: AccountsSliceReducer,
    CartSlice: CartSliceReducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
