import React, { useEffect, useState } from 'react'
import AccountsComponent from '../component/AccountsComponent'
import { useDispatch } from 'react-redux'
import { fetchUserDetails } from './redux/slices/AccountsSlice'
import type { AppDispatch } from './redux/store'

const AccountsContainer = (): JSX.Element => {
  const [customerId] = useState(localStorage.getItem('customerId'))
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    void dispatch(fetchUserDetails(customerId ?? ''))
  }, [])

  return (
    <AccountsComponent />
  )
}

export default AccountsContainer
