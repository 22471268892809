import React from 'react'
import DataTable from 'react-data-table-component'

interface ISizeChartComponent {
  showSizeChart: () => void
}

const SizeChartComponent = ({ showSizeChart }: ISizeChartComponent): JSX.Element => {
  const columns = [
    {
      name: 'Size',
      selector: (row: { size: string }) => row.size
    },
    {
      name: 'Chest',
      selector: (row: { chest: number }) => row.chest
    },
    {
      name: 'Sholder',
      selector: (row: { sholder: number }) => row.sholder
    },
    {
      name: 'Length',
      selector: (row: { length: number }) => row.length
    }
  ]

  const data = [
    {
      id: 1,
      title: 'tshirt',
      size: 'S',
      year: '1988',
      chest: 45,
      sholder: 55,
      length: 197
    },
    {
      id: 2,
      title: 'tshirt',
      size: 'M',
      year: '1984',
      chest: 45,
      sholder: 56,
      length: 200
    },
    {
      id: 3,
      title: 'tshirt',
      size: 'L',
      year: '1988',
      chest: 45,
      sholder: 57,
      length: 201
    },
    {
      id: 4,
      title: 'tshirt',
      size: 'XL',
      year: '1984',
      chest: 45,
      sholder: 55,
      length: 205
    },
    {
      id: 5,
      title: 'Beetlejuice',
      year: '1988',
      size: 'XXL',
      chest: 45,
      sholder: 55,
      length: 210
    },
    {
      id: 6,
      title: 'Ghostbusters',
      year: '1984',
      size: 'XXL',
      chest: 45,
      sholder: 55,
      length: 215
    }
  ]

  const customStyles = {
    rows: {
      style: {
        color: 'white',
        backgroundColor: '#3d0606'
      }
    },
    headCells: {
      style: {
        color: 'white',
        backgroundColor: '#3d0606'
      }
    }
  }

  const SizeTable = (): JSX.Element => {
    return (
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
      />
    )
  }
  return (
    <div>
      <div id="myModal" className="block fixed z-[100] left-0 top-0 w-full h-full overflow-auto bg-black opacity-40 text-white">
      </div>
      <div className="modal-content z-[101] overflow-auto bg-primary opacity-100 m-0 p-5 sm:m-4 sm:p-5 border-solid border w-full sm:w-[60%] fixed top-0 right-0">
        <span onClick={() => { showSizeChart() }} className="close text-white float-right font-[28px] cursor-pointer p-1">&times;</span>
        <h2>Size Chart</h2>
        <SizeTable />
        <div>
          <h2 className='border-solid border-t-white border-t-[1px] pt-2 mt-2'>Measuring T Shirt Size</h2>
          <p>
            Not sure about your t shirt size? Follow these simple steps to figure it out:
          </p>
          <p>
            Shoulder - Measure the shoulder at the back, from edge to edge with arms relaxed on both sides
            Chest - Measure around the body under the arms at the fullest part of the chest with your arms relaxed at both sides.
            Sleeve - Measure from the shoulder seam through the outer arm to the cuff/hem
            Neck - Measured horizontally across the neck Length - Measure from the highest point of the shoulder seam to the bottom hem of the garments
          </p>
        </div>
      </div>

    </div>
  )
}

export default SizeChartComponent
