import { gql } from '@apollo/client'

const GET_LATEST_ITEMS_QUERY = gql`
    {
        alias(input: { pageToken: { pageNumber: 1, perPageCount: 8 } }) {
            aliasList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }

        nairaCuts(input: { pageToken: { pageNumber: 1, perPageCount: 8 } }) {
            nairaCutsList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }

        coOrds(input: { pageToken: { pageNumber: 1, perPageCount: 8 } }) {
            coOrdsList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }

        anarkalis(input: { pageToken: { pageNumber: 1, perPageCount: 8 } }) {
            anarkalisList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }

        kurtis(input: { pageToken: { pageNumber: 1, perPageCount: 8 } }) {
            kurtisList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }
        unstitchedSuits(
            input: { pageToken: { pageNumber: 1, perPageCount: 8 } }
        ) {
            unStitchedSuitsList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                __typename
            }
        }
    }
`
const GET_KURTI_LIST_QUERY = gql`
    query getKurti($pageNumber: Int) {
        kurtis(
            input: { pageToken: { perPageCount: 12, pageNumber: $pageNumber } }
        ) {
            kurtisList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                sizes {
                    size
                }
                description
            }
            pagination {
                maxPages
            }
        }
    }
`
const GET_ALIA_LIST_QUERY = gql`
    query getAlias($pageNumber: Int) {
        alias(
            input: { pageToken: { perPageCount: 12, pageNumber: $pageNumber } }
        ) {
            aliasList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                sizes {
                    size
                }
                description
            }
            pagination {
                maxPages
            }
        }
    }
`

const GET_ANARKALI_LIST_QUERY = gql`
    query getAnarkalis($pageNumber: Int) {
        anarkalis(
            input: { pageToken: { perPageCount: 12, pageNumber: $pageNumber } }
        ) {
            anarkalisList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                sizes {
                    size
                }
                description
            }
            pagination {
                maxPages
            }
        }
    }
`

const GET_NAIRA_CUTS_LIST_QUERY = gql`
    query getNairaCuts($pageNumber: Int) {
        nairaCuts(
            input: { pageToken: { perPageCount: 12, pageNumber: $pageNumber } }
        ) {
            nairaCutsList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                sizes {
                    size
                }
                description
            }
            pagination {
                maxPages
            }
        }
    }
`

const GET_COORD_LIST_QUERY = gql`
    query getCoordSetList($pageNumber: Int) {
        coOrds(
            input: { pageToken: { perPageCount: 12, pageNumber: $pageNumber } }
        ) {
            coOrdsList {
                id
                name
                primaryImage {
                    imageUrl
                }
                pricePerPiece
                sizes {
                    size
                }
                description
            }
            pagination {
                maxPages
            }
        }
    }
`

const GET_DATA_KURTI = gql`
    query getKurti($itemId: Int) {
        kurti(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
            sizes {
                size
                isInStock
            }
        }
    }
`
const GET_DATA_ALIA = gql`
    query getAlia($itemId: Int) {
        alia(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
            sizes {
                size
                isInStock
            }
        }
    }
`
const GET_DATA_ANARKALI = gql`
    query getAnarkali($itemId: Int) {
        anarkali(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
            sizes {
                size
                isInStock
            }
        }
    }
`
const GET_DATA_COORD = gql`
    query getCoordSet($itemId: Int) {
        coOrd(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
            sizes {
                size
                isInStock
            }
        }
    }
`
const GET_DATA_NAIRACUT = gql`
    query getNairaCut($itemId: Int) {
        nairaCut(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
            sizes {
                size
                isInStock
            }
        }
    }
`
const GET_DATA_UNSTITCHED_SUIT = gql`
    query getUnstitchedSuit($itemId: Int) {
        unstitchedSuit(input: { itemId: $itemId }) {
            id
            name
            primaryImage {
                imageUrl
            }
            pricePerPiece
            additionalImages {
                imageUrl
            }
            discountedPricePerPiece
            outOfStock
            __typename
            description
        }
    }
`
const GET_UNSTITCHED_SUIT_LIST = gql`
query getUnstitchedSuits($pageNumber: Int) {
  unstitchedSuits(
    input: { pageToken: { perPageCount: 12, pageNumber: $pageNumber } }
  ) {
    unStitchedSuitsList {
      id
      name
      primaryImage {
        imageUrl
      }
      pricePerPiece
      description
    }
    pagination {
      maxPages
    }
  }
}
`

const GET_PLUS_SIZE_KURTIS = gql`
query getPlusSizeKurtis($pageNumber: Int) {
  kurtis(
    input: {
      pageToken: { perPageCount: 12, pageNumber: $pageNumber }
      plusSizesFilter: { fetchPlusSizesOnly: true }
    }
  ) {
    kurtisList {
      id
      name
      primaryImage {
        imageUrl
      }
      pricePerPiece
      sizes {
        size
      }
      description
    }
    pagination {
      maxPages
    }
  }
}
`

const GET_PLUS_SIZE_ALIAS = gql`
query getPlusSizeAlias($pageNumber: Int) {
  alias(
    input: {
      pageToken: { perPageCount: 12, pageNumber: $pageNumber }
      plusSizesFilter: { fetchPlusSizesOnly: true }
    }
  ) {
    aliasList {
      id
      name
      primaryImage {
        imageUrl
      }
      pricePerPiece
      sizes {
        size
      }
      description
    }
    pagination {
      maxPages
    }
  }
}
`

const GET_PLUS_SIZE_NAIRA_CUTS = gql`
query getPlusSizeNairaCuts($pageNumber: Int) {
  nairaCuts(
    input: {
      pageToken: { perPageCount: 12, pageNumber: $pageNumber }
      plusSizesFilter: { fetchPlusSizesOnly: true }
    }
  ) {
    nairaCutsList {
      id
      name
      primaryImage {
        imageUrl
      }
      pricePerPiece
      sizes {
        size
      }
      description
    }
    pagination {
      maxPages
    }
  }
}
`

const GET_PLUS_SIZE_ANARKALIS = gql`
query getPlusSizeAnarkalis($pageNumber: Int) {
  anarkalis(
    input: {
      pageToken: { perPageCount: 12, pageNumber: $pageNumber }
      plusSizesFilter: { fetchPlusSizesOnly: true }
    }
  ) {
    anarkalisList {
      id
      name
      primaryImage {
        imageUrl
      }
      pricePerPiece
      sizes {
        size
      }
      description
    }
    pagination {
      maxPages
    }
  }
}
`

const GET_PLUS_SIZE_COORD_SETS = gql`
query getPlusSizeCoOrdSets($pageNumber: Int) {
  coOrds(
    input: {
      pageToken: { perPageCount: 12, pageNumber: $pageNumber }
      plusSizesFilter: { fetchPlusSizesOnly: true }
    }
  ) {
    coOrdsList {
      id
      name
      primaryImage {
        imageUrl
      }
      pricePerPiece
      sizes {
        size
      }
      description
    }
    pagination {
      maxPages
    }
  }
}
`

export {
  GET_LATEST_ITEMS_QUERY,
  GET_KURTI_LIST_QUERY,
  GET_ALIA_LIST_QUERY,
  GET_ANARKALI_LIST_QUERY,
  GET_NAIRA_CUTS_LIST_QUERY,
  GET_COORD_LIST_QUERY,
  GET_DATA_ALIA,
  GET_DATA_ANARKALI,
  GET_DATA_COORD,
  GET_DATA_KURTI,
  GET_DATA_NAIRACUT,
  GET_DATA_UNSTITCHED_SUIT,
  GET_UNSTITCHED_SUIT_LIST,
  GET_PLUS_SIZE_KURTIS,
  GET_PLUS_SIZE_ALIAS,
  GET_PLUS_SIZE_ANARKALIS,
  GET_PLUS_SIZE_COORD_SETS,
  GET_PLUS_SIZE_NAIRA_CUTS
}
