import React from 'react'
import ItemsListGrid from '../common/ItemsListGrid'
interface IItemListRecord {
  itemList: Array<Record<string, any>>
}

const LatestItemsPreview = ({ itemList }: IItemListRecord): JSX.Element => {
  const itemTypeNameMap = new Map([
    ['Kurti', 'Kurtis'],
    ['Alia', 'Alia Cuts'],
    ['Anarkali', 'Anarkalis'],
    ['CoOrd', 'Co-Ord Sets'],
    ['NairaCut', 'Naira Cuts'],
    ['UnStitchedSuit', 'UnStitched Suits']
  ])
  return (
    <div className='flex flex-col items-center mb-12'>
      <div className='text-white font-lato font-extralight mt-10 ml-2 text-lg mb-3'>
        New in {itemTypeNameMap.get(itemList?.[0].__typename)}
      </div>
      <ItemsListGrid itemList={itemList} shouldSliceForSmallDevices={true}/>
    </div>
  )
}

export default LatestItemsPreview
