import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_UNSTITCHED_SUIT_LIST } from '../graphql/queries'
import LoadingSpinner from '../component/common/LoadingSpinner'
import { useSearchParams } from 'react-router-dom'
import { UnStitchedSuitsListComponent } from '../component'

const UnStitchedSuitListContainer = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const [pageNumber, setPageNumber] = React.useState(Number(searchParams.get('page')))
  const { loading: dataLoading, error, data } = useQuery(GET_UNSTITCHED_SUIT_LIST, {
    variables: {
      pageNumber
    }
  })
  if (dataLoading) {
    return <LoadingSpinner />
  }

  if (error != null) {
    alert('error loading page')
  }
  return (
    <UnStitchedSuitsListComponent
      unStitchedSuitsList={data?.unstitchedSuits.unStitchedSuitsList}
      maxPages={data?.unstitchedSuits.pagination.maxPages}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber} />
  )
}

export default UnStitchedSuitListContainer
