import React from 'react'
import { HomeComponent } from '../component'
import { GET_LATEST_ITEMS_QUERY } from '../graphql/queries'
import { useQuery } from '@apollo/client'
import LoadingSpinner from '../component/common/LoadingSpinner'

const HomeContainer = (): JSX.Element => {
  const { loading: itemListLoading, data } = useQuery(GET_LATEST_ITEMS_QUERY)

  if (itemListLoading) {
    return <LoadingSpinner />
  }

  return <HomeComponent
    kurtisList={data?.kurtis?.kurtisList}
    anarkaliList={data?.anarkalis?.anarkalisList}
    coordsList={data?.coOrds?.coOrdsList}
    nairaList={data?.nairaCuts?.nairaCutsList}
    aliasList={data?.alias?.aliasList}
    unstitchedSuitsList={data?.unstitchedSuits?.unStitchedSuitsList}
  />
}

export default HomeContainer
