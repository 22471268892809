export default {
  heroTagLine: [
    'absolute',
    'inset-0',
    'z-10',
    'flex',
    'items-center',
    'justify-center',
    'font-mono',
    'text-lg',
    'text-white',
    'sm:text-3xl',
    'md:text-4xl',
    'lg:text-5xl',
    'xl:text-6xl'
  ].join(' '),
  heroSubTagLine: [
    'absolute',
    'inset-0',
    'z-10',
    'mt-20',
    'flex',
    'items-center',
    'justify-center',
    'font-mono',
    'text-base',
    'text-white',
    'sm:text-xl',
    'md:text-1xl',
    'lg:text-2xl',
    'xl:text-3xl'
  ].join(' '),
  heroComponent: ['flex', 'items-center', 'justify-center'].join(' '),
  imageContainer: [
    'relative',
    'z-0',
    'mt-6',
    'flex',
    'w-full',
    'pl-2',
    'pr-2'
  ].join(' '),
  image: ['w-1/3'].join(),
  heroTagLineHighlightedWords: ['text-text-primary'].join(' ')
}
