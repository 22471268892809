import React from 'react'
import WishlistComponent from '../component/WishlistComponent'

const WishlistContainer = (): JSX.Element => {
  return (
    <div>
      <WishlistComponent />
    </div>
  )
}

export default WishlistContainer
