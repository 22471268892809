import React from 'react'
import CartItemTile from './common/CartItemTile'

const WishlistComponent = (): JSX.Element => {
  return (
    <div className='whishlist-container'>
      <div className='text-2xl m-5'>Your Wishes</div>
      <div className='block m-5 border-t-[1px] mt-2 border-[#d8cdcd]'>
        <CartItemTile isCartItem={false} />
        <CartItemTile isCartItem={false} />
      </div>
    </div>
  )
}

export default WishlistComponent
