import React, { useState } from 'react'
interface ICollapsible {
  header: string
  isBodyHidden: boolean
  body: JSX.Element
}
const Collapsible = ({
  header = '',
  isBodyHidden = false,
  body = <div></div>
}: ICollapsible): JSX.Element => {
  const [showBody, setShowBody] = useState(isBodyHidden ? 'hidden' : 'block')
  const handleExpantion = (): void => {
    if (showBody === 'hidden') {
      setShowBody('block')
    } else {
      setShowBody('hidden')
    }
  }

  return (
    <div className='pb-3 border-b-[0.5px] border-s-white'>
      <button onClick={() => { handleExpantion() }} className='bg-transparent text-2xl sm:text-3xl text-white cursor-pointer w-full text-left transition-[0.4s] p-[18px] border-[none] active:bg-[#310505] hover:bg-[#310505]'>{header}
      </button>
      <div className={showBody + ' panel bg-transparent text-white overflow-hidden px-[18px] py-0 pl-[30px]'}>
        <div>{body}</div>
      </div>
    </div>
  )
}

export default Collapsible
