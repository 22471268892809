import React, { useState } from 'react'
import { MdDeleteOutline, MdCurrencyRupee } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import type { AppDispatch } from '../../container/redux/store'
import { updateCartItem } from '../../container/redux/slices/CartSlice'
interface ICartItem {
  cartItemId: string
  fashionItemId: string
  itemType: string
  itemQuantity: number
  size: string
  item: {
    name: string
    primaryImage: {
      imageUrl: string
    }
    pricePerPiece: string
  }
}
interface CartItemTileProps {
  isCartItem?: boolean
  itemData?: ICartItem
}

const CartItemTile = ({
  isCartItem = true, itemData =
  {
    cartItemId: '',
    fashionItemId: '',
    itemType: '',
    itemQuantity: 0,
    size: '',
    item: {
      name: '',
      primaryImage: {
        imageUrl: ''
      },
      pricePerPiece: ''
    }
  }
}: CartItemTileProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>()
  const [customerId] = useState(localStorage.getItem('customerId') ?? '')
  const [displayImgSrc] = useState('http://api.mahodayaa.com/' + String(itemData.item.primaryImage.imageUrl))
  const [quantity] = useState(itemData.itemQuantity)
  const maxQuantity = 100
  const minQuantity = 1
  const handleDecrementQuantity = (): void => {
    if (quantity > minQuantity) {
      void dispatch(updateCartItem({ userId: customerId, cartItemId: itemData.cartItemId, shouldDeleteItem: false, itemQuantity: (quantity - 1) }))
    }
  }

  const handleIncrementQuantity = (): void => {
    if (quantity < maxQuantity) {
      void dispatch(updateCartItem({ userId: customerId, cartItemId: itemData.cartItemId, shouldDeleteItem: false, itemQuantity: (quantity + 1) }))
    }
  }

  const handleCartItemDelete = (): void => {
    void dispatch(updateCartItem({ userId: customerId, cartItemId: itemData.cartItemId, shouldDeleteItem: true, itemQuantity: 0 }))
  }

  return (
    <div className='cartItem-container flex border-b-[1px] border-[#c5b4b4]'>
      <div className='py-5 px-3 w-[125px]'>
        <img
          src={displayImgSrc}
        />
      </div>
      <div className='py-5 px-3 w-full'>
        <div className='text-lg pb-3 flex justify-between'><span className='w-4/5 sm:w-full'>{itemData.item.name} </span><MdDeleteOutline className='cursor-pointer' onClick={handleCartItemDelete} size={25} title='Delete' /></div>
        {isCartItem && <div>
          <div>Size : {itemData.size}</div>
          <div>
            <span>Quantity : </span>
            <button className='p-1 w-9 border border-opacity-50 border-solid border-white' onClick={() => { handleDecrementQuantity() }}>-</button>
            <div className='p-1 inline-block w-10 text-center bg-transparent border border-opacity-50 border-solid border-white' >{quantity}</div>
            <button className='p-1 w-9 border border-opacity-50 border-solid border-white' onClick={() => { handleIncrementQuantity() }}>+</button>
          </div></div>}
        <div className='pt-2'>
          <span>MRP :</span>
          <MdCurrencyRupee className='inline' />
          <span>{itemData.item.pricePerPiece}</span>
        </div>
      </div>
    </div>
  )
}

export default CartItemTile
