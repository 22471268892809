import React from 'react'
import logo from '../../assets/images/optimized/logo.jpg'
import logoStyles from './styles/LogoStyles'

const Logo = (): JSX.Element => {
  return (
        <div className={logoStyles.logo}>
            <img src={logo} />
        </div>
  )
}

export default Logo
