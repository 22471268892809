import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_COORD_LIST_QUERY } from '../graphql/queries'
import LoadingSpinner from '../component/common/LoadingSpinner'
import { useSearchParams } from 'react-router-dom'
import CoordListComponent from '../component/CoordListComponent'

const CoordListContainer = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const [pageNumber, setPageNumber] = React.useState(Number(searchParams.get('page')))
  const { loading: dataLoading, error, data } = useQuery(GET_COORD_LIST_QUERY, {
    variables: {
      pageNumber
    }
  })
  if (dataLoading) {
    return <LoadingSpinner />
  }

  if (error != null) {
    alert('error loading page')
  }
  return (
    <CoordListComponent
      coordsList={data?.coOrds.coOrdsList}
      maxPages={data?.coOrds.pagination.maxPages}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber} />
  )
}

export default CoordListContainer
