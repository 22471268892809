import React from 'react'
import ItemsListGrid from './common/ItemsListGrid'
import ReactPaginate from 'react-paginate'

const KurtiListComponent = ({ kurtisList, pageNumber, setPageNumber, maxPages }:
{
  kurtisList: Array<Record<string, any>> | undefined
  pageNumber: number
  setPageNumber: (pageNumber: number) => void
  maxPages: number | undefined
}): JSX.Element => {
  return (
    <div className='mt-10 mb-10'>
      <ItemsListGrid itemList={kurtisList} shouldSliceForSmallDevices={false} />
      <ReactPaginate
        breakLabel="..."
        nextLabel={maxPages === pageNumber ? '' : '>'}
        onPageChange={(pageNumber) => {
          if ('URLSearchParams' in window) {
            const searchParams = new URLSearchParams(window.location.search)
            searchParams.set('page', (pageNumber.selected + 1).toString())
            const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
            history.pushState(null, '', newRelativePathQuery)
          }
          setPageNumber(Number(pageNumber.selected) + 1)
        }}
        pageCount={maxPages ?? 0}
        forcePage={pageNumber - 1}
        previousLabel={pageNumber === 1 ? '' : '<'}
        renderOnZeroPageCount={null}
        className='w-full flex flex-row justify-center align-middle text-white'
        pageClassName='font-lato border pl-3 pr-3 pt-1 pb-1 m-2'
        previousClassName='font-lato pl-3 pr-3 pt-1 pb-1 m-2'
        nextClassName='font-lato pl-3 pr-3 pt-1 pb-1 m-2'
        activeClassName='bg-white/25'
      />
    </div>
  )
}

export default KurtiListComponent
