export default {
  mobileMenu: ['flex', 'flex-col', 'justify-end'].join(' '),
  mobileMenuIcon: [
    'm-1',
    'cursor-pointer',
    'self-end',
    'text-2xl',
    'sm:text-3xl',
    'md:hidden'
  ].join(' '),
  desktopMenuBar: [
    'hidden',
    'w-full',
    'justify-end',
    'md:flex',
    'xl:-mt-10'
  ].join(' '),
  desktopMenuBarList: ['flex', 'self-end', 'bg-navbar'].join(' '),
  navBarButton: [
    'font-lato',
    'm-4',
    'cursor-pointer',
    'text-xs',
    'text-white',
    'list-none'
  ].join(' '),
  navBarButtonSelected: [
    'bg-white/25'
  ].join(' ')
}
